export default function ({app}) {
    return {
        lang: {
            t: (key, ...params) => app.i18n.t(key, params),
        },
        theme: {
            themes: {
                light: {
                    primary: '#ff7202',
                },
            },
            options: {
                customProperties: true,
            },
        },
    }
}
