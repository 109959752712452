export default {
    en: {
        available: 'Available online',
        on_request: 'Special rate request',
        search_form: {
            israel: 'in Israel',
            world: 'around the world',
            free_search: 'free search',
            city_region_hotel: 'City, Region or Hotel Name',
            all_hotels: 'All hotels',
        },
        sanatoria: 'Sanatoria',
        cancellation_penalties: 'Penalty',
        copyright: '© 2022 ATLANTIS TRAVEL AND TOURISM. ALL RIGHTS RESERVED.',
        hotel_age_limit: 'This property can only be booked for guests aged 16 and over.',
        att_tab_links: {
            transfers_israel: 'Transfers in Israel',
            excursions: 'Excursions',
        },
        hotel_ratings: {
            star: 'The category of the hotel is officially confirmed by the Ministry of Tourism of Israel',
            circle: 'Attention - the category of the hotel is not officially confirmed by the Ministry of Tourism of Israel',
        },
        cancellation_order_text_b2c: `
            Dear client,<br>
            Booking cancellation is possible only through Atlantis Hotels reservation department during working hours.Please send cancellation request to E-mail:
            <a href="mailto:malon@atlantistravel.co.il">malon@atlantistravel.co.il</a><br>
            Working hours: Sun-Thu.: 9: 00-19: 00; Friday: 9: 00-14: 00<br>
            Saturday and Israeli Holidays: closed<br>
            Phone: <a href="tel:03-9400777">03-9400777</a><br>
        `,
        cancellation_order_text_b2b: `
            Dear agent,<br>
            Booking cancellation is possible only through Atlantis Hotels reservation department during working hours.Please send cancellation request to E-mail: 
            <a href="mailto:malon@atlantistravel.co.il">malon@atlantistravel.co.il</a><br>
            Working hours: Sun-Thu.: 9: 00-19: 00; Friday: 9: 00-14: 00<br>
            Saturday and Israeli Holidays: closed<br>
            Phone: <a href="tel:03-9400777">03-6552520</a><br>
        `,
        notes: {
            fill_in_english: 'Please fill in information in English',
            email_for_voucher: 'Attention! Your voucher  will be sent to this email',
            hotel_comment_note:
                'Please write a comment for the hotel. Please note if you add the comment, the reservation status may be changed into the Status "Pending confirmation". Requests specified in comments are not guaranteed.',
            other_comment_note:
                'Please write a comment for the supplier. Please note if you add the comment, the reservation status may be changed into the Status "Pending confirmation". Requests specified in comments are not guaranteed.',
            logged_unregistered: 'You are logged as an unregistered user',
            hebrew: 'Hebrew',
        },
    },
    ru: {
        available: 'Доступно онлайн',
        on_request: 'Специальная цена под запрос',
        search_form: {
            israel: 'по Израилю',
            world: 'по миру',
            free_search: 'свободный поиск',
            city_region_hotel: 'Город, регион или название отеля',
            all_hotels: 'Все отели',
        },
        sanatoria: 'Санатории',
        cancellation_penalties: 'Неустойка',
        copyright: '© 2022 ATLANTIS TRAVEL AND TOURISM. ALL RIGHTS RESERVED.',
        hotel_age_limit: 'Данный отель можно забронировать только для гостей от 16 лет и старше.',
        non_refundable: 'При отмене стоимость не возвращается',
        att_tab_links: {
            transfers_israel: 'Подвозки в Израиле',
            excursions: 'Экскурсии',
        },
        hotel_ratings: {
            star: 'Градация отеля официально подтверждена министерством туризма Израиля',
            circle: 'Внимание - градация отеля официально не подтверждена министерством туризма Израиля',
        },
        cancellation_order_text_b2c: `
            Уважаемый клиент,<br>
            Отмена заказа возможна только через отдел бронирования в рабочее время. <br>
            Для отмены заказа, пожалуйста, отправьте письменный запрос на E-mail:
            <a href="mailto:malon@atlantistravel.co.il">malon@atlantistravel.co.il</a><br>
            Часы работы: Вс.-Чт.: 9:00-19:00; Пятница: 9:00-14:00; <br>
            Суббота и израильские праздники: выходной<br>
            Телефон: <a href="tel:03-9400777">03-9400777</a><br>
        `,
        cancellation_order_text_b2b: `
            Уважаемый агент,<br>
            Отмена заказа возможна только через отдел бронирования в рабочее время.<br> 
            Для отмены заказа, пожалуйста, отправьте письменный запрос на E-mail: 
            <a href="mailto:malon@atlantistravel.co.il">malon@atlantistravel.co.il</a><br>
            Часы работы: Вс.-Чт.: 9:00-19:00; Пятница: 9:00-14:00; <br>
            Суббота и израильские праздники: выходной<br>
            Телефон: <a href="tel:03-9400777">03-6552520</a><br>
        `,
        notes: {
            fill_in_english: 'Пожалуйста, вносите информацию на Английском языке',
            email_for_voucher: 'Внимание! На данный почтовый адрес будет отправлен ваучер',
            hotel_comment_note:
                'Укажите комментарий по заказу для отправки в отель. Обратите внимание, что в некоторых случаях при наличии комментария заказ может перейти в состояние "В ожидании подтверждения". Выполнение запросов, указанных в комментариях, не гарантируется.',
            other_comment_note:
                'Укажите комментарий по заказу для отправки поставщику. Обратите внимание, что в некоторых случаях при наличии комментария заказ может перейти в состояние "В ожидании подтверждения". Выполнение запросов, указанных в комментариях, не гарантируется.',
            logged_unregistered: 'Вы вошли как незарегистрированный пользователь',
            hebrew: 'Иврите',
        },
    },
}
