import {Action, Module} from 'vuex-module-decorators'
import HotelsRuntime from '@/store/modules/hotels/hotelsRuntime'
import {newSearch, partialSearch} from '@/store/modules/hotels/hotelsRuntime'
import {EventBus, SEARCH_EVENT} from '@/utils/event-bus'

@Module({name: 'hotelsRuntime', stateFactory: true, namespaced: true})
export default class HotelsRuntimeMod extends HotelsRuntime {
    @Action
    async search(rq) {
        this.START_SEARCH()
        newSearch.call(this, rq)
        EventBus.$emit(SEARCH_EVENT)
        let stopSearchTimer = {
            timer: null,
            start: () => {
                this.timer = setTimeout(() => {
                    this.stopSearch()
                }, 90000)
            },
            stop: () => clearInterval(this.timer),
        }

        try {
            stopSearchTimer.start()
            await partialSearch.call(this, rq, ['price'])
            stopSearchTimer.stop()
            // eslint-disable-next-line no-empty
        } catch (e) {
        } finally {
            this.STOP_SEARCH()
        }
    }
}
